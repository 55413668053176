export default {
    "other": "Khác",
    "100": "Nhà cung cấp chưa xuất hàng",
    "200": "Hàng đang trên đường về kho trung gian",
    "300": "Hàng đã về kho trung gian",
    "400": "Hàng đang trên đường vận chuyển từ kho trung gian tới kho chính",
    "450": "Hàng sẵn sàng cho team MKT chạy",
    "500": "Hàng đã về tới kho chính",
    "600": "Đã hủy"
}
